/**
 * MS-营销中心接口
 */
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/marketing`; //服务前缀

//查询窗口优惠列表 - 马鹏浩
export const windowDiscountFindList = () => {
  return http.get(`${prefix}/window/discount/find/list`);
};

//整单折扣计算优惠金额-许家炜
export const windowDiscountDiscountedPrice = data => {
  return http.post(`${prefix}/window/discount/discounted/price`, data);
};

//计算优惠金额-许家炜
export const windowActivityDiscountedPrice = data => {
  return http.post(`${prefix}/window/activity/discounted/price`, data);
};

//窗口查询可用优惠券-许家炜
export const windowCouponActivityList = data => {
  return http.post(`${prefix}/window/coupon/activity/list`, data);
};

// 根据id查询活动详情 - 马鹏浩
export const getMarketingActivityById = data => {
  return http.get(`${prefix}/get/marketing_activity/by/id`, data);
};

//【market112】窗口兑换优惠券
export function windowRedeemCoupons(data) {
  return http.post(`${prefix}/window/redeem/coupons`, data);
}

/**
 *营销活动信息
 */
// 【market026】根据会员号和状态码获取优惠券集合 - 马鹏浩
export function marketingCouponList(data) {
  return http.get(`${prefix}/marketing/coupon/list`, data);
}
