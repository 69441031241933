<template>
  <QmpLayout title="产品列表">
    <div class="root">
      <!-- 产品列表 -->
      <div class="list" v-for="item in productData" :key="item.id">
        <img
          :src="item.skuImages || require('../../../assets/images/new/pt.png')"
          alt=""
        />
        <div class="detila">
          <h1 class="font-xxxl flex-between">
            {{ item.productName }} <span>x{{ item.buyNum }}</span>
          </h1>
          <p>
            使用有效期：{{ item.beginValidTime | date }} ~
            {{ item.endValidTime | date }}
          </p>
          <p>预约日期：{{ item.useDate | date }}</p>
          <p>
            预约时段：{{ item.appointmentPeriodStartTime | date
            }}{{ item.appointmentPeriodEndTime | date }}
          </p>
          <p>购买数量：{{ item.buyNum }}</p>
        </div>
      </div>
    </div>
    <template slot="footer">
      <div class="text-r ph-2x">
        <a-pagination
          v-model="current"
          :total="total"
          @change="changeProduct"
          :defaultPageSize="pageSize"
          show-less-items
        />
      </div>
    </template>
  </QmpLayout>
</template>

<script>
import QmpLayout from "@/components/common/QmpLayout.vue";
import { orderMemberProduct } from "@/api/search";
export default {
  name: "productList",
  components: {
    QmpLayout
  },
  data() {
    return {
      productData: [],
      keyword: this.$route.query.keyword,
      pageSize: 5,
      current: 1,
      total: 0
    };
  },
  created() {
    this.getOrderMemberProduct();
  },
  methods: {
    changeProduct(page) {
      this.current = page;
      this.getOrderMemberProduct();
    },
    getOrderMemberProduct() {
      orderMemberProduct({
        keyword: this.keyword,
        size: this.pageSize,
        page: this.current
      }).then(({ records, total }) => {
        this.productData = records;
        this.total = total;
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.list {
  display: flex;
  align-items: center;
  padding: 32px 30px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #fff;

  img {
    width: 200px;
    height: 200px;
    margin-right: 33px;
    border-radius: 8px;
  }
  p {
    font-size: 22px;
    color: @gray;
  }
  .detila {
    flex: 1;
    line-height: 2;
  }
}
</style>
