<template>
  <div style="width:100%;">
    <div class="title-block">会员资产</div>
    <a-row class="pv-2x" :gutter="[16, 8]" type="flex" justify="space-between">
      <a-col class="flex-5" :xl="6" :lg="12">
        <div class="height-100  member-item img-1">
          <h2 class="font-xxm">
            {{ vipRes.currentLevelName || "临时会员" }}
          </h2>
          <div class="font-n">
            <p class="mar-you" v-if="vipRes.whetherTemporaryMember === 0">
              <span>有效期: </span>
              <span v-if="vipRes.privilegeEndDate">
                {{ vipRes.privilegeStartDate | date }} ~
                {{ vipRes.privilegeEndDate | date }}
              </span>
              <span v-else>永久有效</span>
            </p>
            <p class="mar-you" v-else>
              有效期: {{ vipRes.endValidTime | date }}
            </p>
            <div class="flex-between">
              <div>
                消费折扣:
                <span>
                  <template v-if="vipRes.counsumeDiscount === 1">
                    无折扣
                  </template>
                  <template v-else-if="vipRes.memberLevelRate"
                    >{{ vipRes.memberLevelRate }}折</template
                  >
                  <template v-else>-</template>
                </span>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col class="flex-5" :xl="6" :lg="12">
        <div class="height-100 member-item img-2">
          <div class="mar-20">
            <h2 style="font-size: 18px;">会员可用余额(元)</h2>
            <h2 class="font-xxxxl mt-1x">
              {{ vipRes.buyBalance || 0 | money }}
            </h2>
          </div>
          <div class="mt-x flex-around">
            <QmpButton size="small" @click="recharge" type="primary"
              >充值</QmpButton
            >
            <QmpButton size="small" @click="balanceDetailsFn">明细</QmpButton>
            <QmpButton size="small" @click="refundDetailsFn">退款</QmpButton>
          </div>
        </div>
      </a-col>
      <a-col class="flex-5" :xl="6" :lg="12">
        <div class="height-100 member-item flex-b img-3">
          <div class="font-n">
            <h2 class="font-xxm mb-2x">积分</h2>
            <p>
              最多可抵扣:
              <span>{{ vipRes.deductionMoney | money }}</span>
            </p>
            <p class="mv-2x">
              会员可用积分:
              <span>{{ vipRes.usableIntegral || "-" }}</span>
            </p>
            <div class="flex-between">
              <p>
                本月将到期积分:
                <span>{{ vipRes.monthlyExpireIntegral || "-" }}</span>
              </p>
            </div>
          </div>
          <QmpButton size="small" @click="pointsDetailsFn">明细</QmpButton>
        </div>
      </a-col>
      <a-col class="flex-5" :xl="6" :lg="12">
        <div class="height-100 member-item  img-3">
          <div class="flex-b mb-4x">
            <div>
              <h2 class="font-xxm">成长值</h2>
              <h2 class="font-xxxm mt-2x">
                {{
                  vipRes.totalGrowth === 0 || vipRes.totalGrowth
                    ? vipRes.totalGrowth
                    : "-"
                }}
              </h2>
            </div>

            <QmpButton size="small" @click="growthDetailsFn">明细</QmpButton>
          </div>
          <div class="flex-between gray">
            <p class="font-n mt-x">
              *再获取
              <span class="red">{{ vipRes.needGrowth || "-" }}</span
              >个成长值可升级至 <span>{{ vipRes.nextLevel }}</span> 级会员
            </p>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 积分明细弹窗 -->
    <a-modal
      centered
      title="积分明细"
      :visible="pointsDetails"
      :confirm-loading="confirmLoading"
      @cancel="close"
      width="80%"
    >
      <div class="content-modal">
        <div class="search">
          <a-input
            size="large"
            placeholder="请输入订单号"
            allow-clear
            v-model="searchKey"
            @pressEnter="onSearch"
          />
          <div class="search-icon pointer gray" @click="onSearch">
            <span class="line"></span>
            <i class="icon iconfont icon-sousuo"></i>
            <span>搜索</span>
          </div>
        </div>
        <div class="list" v-for="item in pointsDetailsList" :key="item.id">
          <a-row>
            <a-col :span="8">
              <!-- 开通特权 -->
              <div>
                <p class="font-2x">总单号：{{ item.totalOrderId }}</p>
                <p class="mid">
                  变更时间：{{ item.createTime | date("YYYY-MM-DD hh:mm:ss") }}
                </p>
              </div>
            </a-col>
            <a-col :span="8">
              <!-- 会员可用余额 -->
              <div>
                <p>变更数量：{{ item.changeNum }}</p>
                <p class="font-3x">交易渠道：{{ item.tradingTypeText }}</p>
              </div>
            </a-col>
            <a-col :span="8">
              <!-- 会员可用余额 -->
              <div>
                <p>变更后数量：{{ item.changeTotal }}</p>
                <p class="font-3x">操作人：{{ item.creatorName || "-" }}</p>
              </div>
            </a-col>
          </a-row>
          <p>变更备注：{{ item.remark || "-" }}</p>
        </div>
        <div v-if="!pointsDetailsList.length" class="emty">
          <img src="../../../../assets/images/new/kong.png" alt="" />
          <p class="gray">无此订单，请重新输入查询</p>
        </div>
      </div>
      <div slot="footer">
        <a-pagination
          v-model="pages.current"
          :total="pages.total"
          @change="ChangePointsDetails"
          :defaultPageSize="pages.pageSize"
          show-less-items
        />
      </div>
    </a-modal>
    <!-- 成长值弹窗 -->
    <a-modal
      centered
      title="成长值"
      :visible="growthDetails"
      :confirm-loading="confirmLoading"
      @cancel="close"
      width="80%"
    >
      <div class="content-modal">
        <div class="search">
          <a-input
            size="large"
            placeholder="请输入订单号"
            v-model="searchKey"
            @pressEnter="onSearch"
            allow-clear
          />
          <div class="search-icon pointer gray" @click="onSearch">
            <span class="line"></span>
            <i class="icon iconfont icon-sousuo"></i>
            <span>搜索</span>
          </div>
        </div>
        <div class="list" v-for="item in growthDetailsList" :key="item.id">
          <a-row>
            <a-col :span="8">
              <!-- 开通特权 -->
              <div>
                <p class="font-2x">总单号：{{ item.totalOrderId }}</p>
                <p class="mid">变更时间：{{ item.createTime }}</p>
                <p class="mid">交易渠道：{{ item.tradingTypeText }}</p>
              </div>
            </a-col>
            <a-col :span="8">
              <!-- 会员可用余额 -->
              <div>
                <p>变动成长值：{{ item.changeNum }}</p>
                <p class="font-3x">变动类型：{{ item.changeTypeText }}</p>
                <p class="font-3x">
                  业务类型：{{ item.memberBusinessTypeText }}
                </p>
              </div>
            </a-col>
            <a-col :span="8">
              <!-- 会员可用余额 -->
              <div>
                <p>变动后成长值：{{ item.changeTotal }}</p>
                <p class="font-3x">操作人：{{ item.creatorName || "-" }}</p>
              </div>
            </a-col>
          </a-row>
          <p>变更备注：{{ item.remark || "-" }}</p>
        </div>
        <div v-if="!growthDetailsList.length" class="emty">
          <img
            src="https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16539/1653990191904.png"
            alt=""
          />
          <p class="gray">无此订单，请重新输入查询</p>
        </div>
      </div>
      <div slot="footer">
        <a-pagination
          v-model="pages.current"
          :total="pages.total"
          @change="ChangeGrowthDetails"
          :defaultPageSize="pages.pageSize"
          show-less-items
        />
      </div>
    </a-modal>
    <!-- 会员余额明细 -->
    <a-modal
      centered
      title="余额明细"
      :visible="balanceDetails"
      :confirm-loading="confirmLoading"
      @cancel="close"
      :footer="null"
      width="80%"
    >
      <div class="list">
        <div class="item" v-for="item in particularsData" :key="item.id">
          <div class="flex-start">
            <p class="name text-1">资产名称：{{ item.productName }}</p>
            <p class="price">金额：￥{{ item.remainingSum | money }}</p>
          </div>
          <div class="between">
            <p>可用产品：</p>
            <p class="text-2 between-flex">
              {{ item.applicationNameArr }}
            </p>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 退款明细 -->
    <a-modal
      centered
      title="退款"
      :visible="refundDetails"
      :confirm-loading="confirmLoading"
      @cancel="close"
      :footer="null"
      width="80%"
    >
      <div class="refund">
        <div class="item" v-for="item in refundList" :key="item.id">
          <div class="left">
            <p>充值单号：{{ item.totalOrderId }}</p>
            <p>支付方式：{{ item.payWayName }}</p>
            <p>现金价值：￥{{ item.paymentAmount | money }}</p>
          </div>

          <div class="right">
            <p>充值时间：{{ item.createTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
            <p>资产金额：￥{{ item.changeNum | money }}</p>
            <QmpButton
              size="small"
              :disabled="
                item.payStatus !== 2 ||
                  item.remainingSum <= 0 ||
                  item.refundStatus == 0
              "
              @click="refund(item)"
              >退款</QmpButton
            >
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 退款 -->
    <a-modal
      centered
      @cancel="refundPop = false"
      title="退款"
      :visible="refundPop"
      @ok="refundSuccess"
      width="80%"
    >
      <div class="p-4x">
        <div class="flex-start">
          <p class="w-90">订单号：</p>
          <p>{{ detailItemPop.totalOrderId }}</p>
        </div>
        <div class="flex-start mt-1x">
          <p class="w-90">资产名称：</p>
          <p>{{ detailItemPop.productName }}</p>
        </div>
        <div class="flex-start mt-1x">
          <p class="w-90">会员余额：</p>
          <p>{{ detailItemPop.remainingSum | money }}</p>
        </div>
        <div class="flex-start mt-1x">
          <p class="w-90">退款数额：</p>
          <p>{{ detailItemPop.changeNum | money }}</p>
        </div>
        <div class="flex-start mt-1x">
          <p class="w-90">现金价值：</p>
          <p>{{ detailItemPop.paymentAmount | money }}</p>
        </div>
        <div class="flex-start mt-1x">
          <p class="w-90">折算后应退金额：</p>
          <p>{{ detailItemPop.paymentAmount | money }}</p>
        </div>
        <div class="flex-start mt-1x">
          <p class="w-90">退款备注：</p>
          <p class="flex-1">
            <a-input
              v-model="remark"
              placeholder="请输入备注"
              type="textarea"
            ></a-input>
          </p>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  findMemberProperty,
  getintegralRecord,
  growthRecord,
  findMemberAssetsInfo,
  memberRefundBalance,
  memberAssetsInfo
} from "../../../../api/member";
export default {
  name: "MemberAsset.vue",
  props: {
    vipRes: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      popupType: 1, // 获取成长值还是积分明细的标识
      confirmLoading: false, // 是否异步关闭
      pointsDetails: false, // 积分明细弹窗
      searchKey: "", // 积分弹窗 查询关键字
      // 积分分页
      pages: {
        current: 1,
        pageSize: 8,
        total: 0
      },
      pointsDetailsList: [], // 积分明细 数据
      growthDetails: false, // 成长明细
      growthDetailsList: [], // 成长明细 数据
      particularsData: [], // 会员余额明细 数据
      balanceDetails: false,
      refundDetails: false,
      refundList: [],
      refundPop: false,
      detailItem: {},
      remark: "",
      detailItemPop: {}
    };
  },
  methods: {
    // 跳转到充值页面
    recharge() {
      this.$router.push({
        path: "/recharge",
        query: {
          mobile: this.vipRes.mobile,
          memberId: this.vipRes.id
        }
      });
    },
    refundSuccess() {
      let row = this.detailItem;
      memberAssetsInfo({
        memberBusinessType: 3, // 会员退余额类型 3
        buyChannels: 6, // 会员退余额类型 6
        tradingType: 2, // 退余额/减少 2
        memberAssetsInfoId: row.id, // 流水表id
        originOrderId: row.totalOrderId, // 原订单号id
        merchantId: row.merchantId, // 景区id
        memberId: row.memberId, // 会员id
        remark: this.remark
      }).then(() => {
        this.refundPop = false;
        // 退款成功
        this.getRefundbalanceList();
      });
    },
    // 退款操作
    refund(item) {
      console.log("退款", item);
      this.detailItem = item;
      memberRefundBalance({
        merchantId: item.merchantId,
        memberId: item.memberId,
        memberAssetsInfoId: item.id
      }).then(res => {
        this.remark = "";
        this.refundPop = true;
        this.detailItemPop = res;
      });
    },
    // 获取会员退款余额列表
    getRefundbalanceList() {
      findMemberAssetsInfo({
        memberId: this.vipRes.id
      })
        .then(res => {
          this.refundList = res;
          this.refundDetails = true;
        })
        .catch(() => {});
    },
    // 打开会员 退款明细
    refundDetailsFn() {
      this.popupType = 4;
      this.integralRecord();
    },
    // 查看余额明细
    balanceDetailsFn() {
      this.popupType = 3;
      this.integralRecord();
    },
    // 查看成长值
    growthDetailsFn() {
      this.popupType = 2;
      this.integralRecord();
    },
    // 分页
    ChangeGrowthDetails(page) {
      this.pages.current = page;
      this.integralRecord();
    },
    //   查询积分明细
    onSearch() {
      this.integralRecord();
    },
    // 改变积分明细 分页
    ChangePointsDetails(page) {
      this.pages.current = page;
      this.integralRecord();
    },
    // 点击取消按钮隐藏 积分明细弹窗
    pointsDetailsFn() {
      this.popupType = 1;
      this.integralRecord();
    },
    //   积分明细弹窗控制显示隐藏
    close() {
      this.pages = {
        current: 1,
        pageSize: 8,
        total: 0
      };
      this.searchKey = "";
      if (this.popupType === 1) {
        this.pointsDetails = false;
      } else if (this.popupType === 2) {
        this.growthDetails = false;
      } else if (this.popupType === 3) {
        this.balanceDetails = false;
      } else if (this.popupType === 4) {
        this.refundDetails = false;
      }
    },
    // 获取积分(成长)明细
    integralRecord() {
      if (this.popupType === 1) {
        // 积分明细
        getintegralRecord({
          id: this.vipRes.id,
          totalOrderId: this.searchKey,
          page: this.pages.current,
          size: this.pages.pageSize
        }).then(res => {
          this.pointsDetailsList = res.records;
          this.pages.total = res.total;
          this.pointsDetails = true;
        });
      } else if (this.popupType === 2) {
        growthRecord;
        // 积分明细
        growthRecord({
          id: this.vipRes.id,
          totalOrderId: this.searchKey,
          page: this.pages.current,
          size: this.pages.pageSize
        }).then(res => {
          this.growthDetailsList = res.records;
          this.pages.total = res.total;
          this.growthDetails = true;
        });
      } else if (this.popupType === 3) {
        // 获取会员 余额 明细
        findMemberProperty({
          memberId: this.vipRes.id
        }).then(res => {
          res.forEach(item => {
            let applicationNameArr = [];
            if (item.applicableProducts) {
              item.applicableProducts.forEach(temp => {
                applicationNameArr.push(temp.productName);
              });
              item.applicationNameArr = applicationNameArr.join(",");
            }
          });
          this.particularsData = res || [];
          this.balanceDetails = true;
        });
      } else if (this.popupType === 4) {
        // 获取会员退款余额列表
        this.getRefundbalanceList();
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.content-modal {
  width: 100%;
  .search {
    position: relative;
    width: 420px;
    margin: 24px 0 24px 27px;
    .search-icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      font-size: 18px;
      .icon-sousuo {
        margin: 0 5px 0 20px;
        font-size: 24px;
      }
      .line {
        display: inline-block;
        width: 1px;
        height: 18px;
        background-color: @gray;
        margin-left: 20px;
      }
    }
  }
  .list {
    padding: 0 26px;
    border-top: 1px solid #e8e8e8;
    line-height: calc(5 / 2);
  }
}
/deep/.ant-modal-body {
  padding: 0;
}
.emty {
  font-size: 18px;
  text-align: center;
  img {
    width: 88px;
    height: 51px;
    margin-bottom: 22px;
  }
}
.height-100 {
  height: 100%;
}
.member-item {
  flex-shrink: 0;
  overflow: hidden;
  /*width: 388px;*/
  border-radius: 16px;
  padding: 16px 16px !important;
  /*box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);*/
  &.img-1 {
    background-image: url("../../../../assets/images/new/vip.png");
    background-size: cover;
  }
  &.img-2 {
    background: #eff5ff;
  }
  &.img-3 {
    background: #f6f6f6;
  }
}
.font-xxm {
  font-size: 22px;
}
.font-xxxm {
  font-size: 28px;
}
.flex-b {
  display: flex;
  justify-content: space-between;
}
.mar-you {
  margin: 42px 0 14px;
}
.mar-20 {
  margin-bottom: 20px;
}
.flex-5 {
  flex: 50%;
}
// 会员余额
.list {
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  .item {
    font-size: 18px;
    line-height: 2;
    padding: 18px 25px;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    .name {
      width: 300px;
    }
    .price {
      margin-left: 100px;
    }

    .between {
      display: flex;
      justify-content: space-between;
      .between-flex {
        flex: 1;
      }
    }
  }
}
// 退款
.refund {
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  .item {
    border-top: 1px solid #e8e8e8;
    line-height: 2;
    display: flex;
    padding: 15px 25px;
    font-size: 18px;
    .right {
      flex: 1;
      margin-left: 100px;
      text-align: right;
      p {
        text-align-last: left;
      }
      button {
        color: #ff1e1e;
        border: 1px solid #ff1e1e;
      }
    }
  }
}
</style>
