<template>
  <div>
    <!-- 会员信息 start -->
    <div class="title-block">会员信息</div>
    <div class="vip-text">
      <a-row>
        <a-col :span="7"> 姓名：{{ memberInfoOne.name }} </a-col>
        <a-col :span="7">
          性别：{{
            !memberInfoOne.sex ? "未知" : memberInfoOne.sex === 1 ? "女" : "男"
          }}
        </a-col>
        <a-col :span="10"> 手机：{{ memberInfoOne.mobile || "-" }} </a-col>
      </a-row>
      <a-row>
        <a-col :span="7"> 生日：{{ memberInfoOne.birthday | date }} </a-col>
        <a-col :span="7">
          会员来源：{{ memberInfoOne.sourceText || "-" }}
        </a-col>
        <a-col :span="10">
          身份证：{{ memberInfoOne.idCardNumber || "-" }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7">
          成为会员：{{ memberInfoOne.createTime | date }}
        </a-col>
        <a-col :span="7">
          最后消费：{{ memberInfoOne.lastConsumeTime | date }}
        </a-col>
      </a-row>
    </div>
    <!-- 会员信息 end -->
    <!-- 凭证信息 start -->
    <div class="title-block">凭证信息</div>
    <div class="vip-text">
      <a-row>
        <a-col :span="5">
          人像：
          <img
            class="img"
            :src="
              memberInfoOne.facePersonUrl ||
                require('../../../../assets/images/new/renxiang.png')
            "
            alt=""
          />
        </a-col>
        <a-col :span="8">
          <span>卡腕带：{{ memberInfoOne.wristBand || "-" }}</span>
          <span>手机号：{{ memberInfoOne.mobile || "-" }}</span>
        </a-col>
        <a-col>
          <span>证件号：{{ memberInfoOne.idNo || "-" }}</span>
          <span>票号：{{ memberInfoOne.ticketNo || "-" }}</span>
        </a-col>
      </a-row>
    </div>
    <!-- 凭证信息 end -->
  </div>
</template>

<script>
export default {
  name: "MemberHeader",
  props: {
    memberInfoOne: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      memberId: "" //会员id
    };
  }
};
</script>
<style lang="less" scoped>
.vip-text {
  padding: 23px 0 30px 19px;
  line-height: 2;
  font-size: 18px;
  color: #767676;
  img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
  }
  span {
    display: block;
  }
}
</style>
