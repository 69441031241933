<template>
  <div>
    <h1>优惠券</h1>
    <!-- tabs -->
    <ul class="nav">
      <li
        @click="isNative(1)"
        class="pointer"
        :class="[tabActive == 1 ? 'native' : '']"
      >
        可用
      </li>
      <li
        @click="isNative(2)"
        class="pointer"
        :class="[tabActive == 2 ? 'native' : '']"
      >
        过期
      </li>
      <li
        @click="isNative(3)"
        class="pointer"
        :class="[tabActive == 3 ? 'native' : '']"
      >
        已使用
      </li>
    </ul>
    <!-- 优惠券列表 -->
    <a-row
      type="flex"
      justify="space-between"
      :gutter="[24, 24]"
      v-if="couponsList.length"
    >
      <a-col
        :xxl="6"
        :xl="12"
        :lg="12"
        v-for="(item, index) in couponsList"
        :key="index"
        style="flex: 50%"
      >
        <div class="coupon-wrapper">
          <header
            class="coupon-amount"
            :class="
              tabActive === 1 ? 'amount-top-bg' : 'amount-top-bg-disabled'
            "
          >
            <div
              :class="
                tabActive === 1 ? 'amount-top-img' : 'amount-top-img-disabled'
              "
            >
              <b class="font-2x">
                {{ item.discountedPriceText }}
              </b>
              <p class="mt-1x">{{ item.useConditionsText }}</p>
            </div>
            <div class="circle"></div>
            <div class="circle left"></div>
          </header>
          <div class="p-2x gray-6 lh-2 white">
            <p class="fonnt-n">{{ item.giftCouponNames }}</p>
            <p class="fonnt-n">编号：{{ item.giftCouponId }}</p>
            <p class="fonnt-n">
              有效期：{{ item.activityStartDate | date }} ~
              {{ item.activityEndDate | date }}
            </p>
            <a-row class="mb-1x">
              <a-col :span="6" class="text">限产品：</a-col>
              <a-col :span="18" class="text-2">{{ item.productText }}</a-col>
            </a-row>
            <div class="text-c primary pointer">
              <span @click="getMarketingActivity(item.id)"
                >查看全部适用产品</span
              >
              <a-icon type="down" class="mt-x" />
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-empty v-else />
    <div
      v-if="couponsListCopy.length > 2"
      class="text-c mt-2x primary pointer"
      @click="isShowMore"
    >
      {{ moreTitle }}
      <a-icon v-show="moreTitle == '展开更多'" type="down" />
      <a-icon v-show="moreTitle == '收起'" type="up" />
    </div>
    <!-- 适用产品 弹窗 -->
    <a-modal
      centered
      v-model="showLookAppProducts"
      title="查看适用产品"
      @cancel="lookAppProductsCancel"
      width="80%"
    >
      <div class="main-box">
        <a-row :gutter="16" class="p-3x">
          <a-col class="gutter-row" :span="6">
            <div class="gutter-box text-c">
              <a-select
                size="large"
                placeholder="请选择"
                style="width: 150px"
                v-model="search.productType"
                @change="changeSearchBtn"
              >
                <a-select-option label="全部" value="" key="0"
                  >全部</a-select-option
                >
                <a-select-option
                  :value="item.cid1"
                  v-for="item in selectOptions2"
                  :key="item.cid1"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="9">
            <div class="gutter-box text-c">
              <a-input-search
                size="large"
                allow-clear
                placeholder="请输入产品名称"
                v-model="search.productName"
                @pressEnter="searchBtn"
                @search="searchBtn"
              />
            </div>
          </a-col>
          <a-col class="gutter-row" :span="9">
            <div class="gutter-box text-c">
              <a-input-search
                size="large"
                allow-clear
                placeholder="请输入商户名称"
                v-model="search.merchantName"
                @pressEnter="searchBtn"
                @search="searchBtn"
              />
            </div>
          </a-col>
        </a-row>
        <div class="list lh-2" v-for="item in tableList" :key="item.id">
          <p>产品名称：{{ item.name }}</p>
          <a-row>
            <a-col :span="10" :order="4">
              <p>销售价格：{{ item.sellingPrice | money }}</p>
              <p>所属商户：{{ item.merchantName }}</p>
            </a-col>
            <a-col :span="10" :order="3">
              <p>产品编号：{{ item.productNo }}</p>
              <p>自定义分类：{{ item.categoryName }}</p>
            </a-col>
            <a-col :span="4" :order="2">
              <p>产品类型：{{ item.cid1 }}</p>
            </a-col>
          </a-row>
        </div>
      </div>
      <template slot="footer" class="flex-end">
        <a-pagination
          v-model="pagination.current"
          :total="pagination.total"
          @change="paginationChange"
          :defaultPageSize="pagination.pageSize"
          show-less-items
        />
      </template>
      <div v-if="!tableList.length" class="emty">
        <img
          src="https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16539/1653990191904.png"
          alt=""
        />
        <p class="gray">无此订单，请重新输入查询</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { productFrontCouponapplyProduct } from "@/api/search";
import {
  getMarketingActivityById,
  marketingCouponList
} from "../../../../api/marketing";
export default {
  name: "MemberCoupon",
  props: {
    memberId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      tabActive: 1,
      couponsList: [], // 未使用优惠券列表
      couponsListCopy: [], // 缓存未使用优惠券列表
      moreTitle: "展开更多",
      merchantIds: [],
      showLookAppProducts: false,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20"],
        showSizeChanger: true,
        showQuickJumper: true
      },
      search: {
        productName: "",
        merchantName: "",
        productType: ""
      },
      tableList: [],
      tableLoading: false,
      selectOptions2: [
        { name: "门票", cid1: "1365211690130337793" },
        { name: "综合套票", cid1: "1365211690470076417" },
        { name: "项目套餐", cid1: "1365211691942277121" },
        { name: "门票资产套餐", cid1: "1365211695276748801" },
        { name: "零售商品", cid1: "1365211692399456258" },
        { name: "酒店", cid1: "1365211693653553153" }
      ]
    };
  },
  created() {
    this.getCouponsList();
  },
  methods: {
    getDataList() {
      this.tableLoading = true;
      this.tableList = [];
      productFrontCouponapplyProduct({
        ids: this.merchantIds.join(","),
        name: this.search.productName,
        merchantName: this.search.merchantName,
        cid1: this.search.productType,
        size: this.pagination.pageSize,
        page: this.pagination.current
      })
        .then(res => {
          if (res.records !== null && res.records.length) {
            this.pagination.total = res.total;
            this.tableList = res.records;
            this.showLookAppProducts = true;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.tableLoading = false;
        });
    },
    paginationChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    changeSearchBtn() {
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.getDataList();
    },
    searchBtn() {
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.getDataList();
    },
    lookAppProductsCancel() {
      this.search = {
        productName: "",
        merchantName: "",
        productType: ""
      };
      this.showLookAppProducts = false;
    },
    // 查看可用产品
    getMarketingActivity(id) {
      getMarketingActivityById({ id }).then(res => {
        this.merchantIds = res.applicableProducts.map(item => {
          return item.products;
        });
        this.getDataList();
      });
    },
    isShowMore() {
      if (this.moreTitle == "展开更多") {
        this.couponsList = this.couponsListCopy;
        this.moreTitle = "收起";
      } else if (this.moreTitle == "收起") {
        this.couponsList = this.couponsListCopy.slice(0, 2);
        this.moreTitle = "展开更多";
      }
    },
    isNative(index) {
      this.tabActive = index;
      this.getCouponsList(index);
    },
    // 获取未使用优惠券列表
    getCouponsList(index) {
      this.couponsList = [];
      marketingCouponList({
        memberId: this.memberId,
        status: index
      }).then(res => {
        this.couponsListCopy = res || [];
        this.couponsList = this.couponsListCopy.slice(0, 2);
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.nav {
  width: 368px;
  height: 44px;
  border-radius: 6px;
  border: 1px solid #c8c8c8;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px 0 29px;
  li {
    height: 100%;
    border-radius: 6px;
    line-height: 44px;
    flex: 1;
    color: @gray;
    text-align: center;
  }
  .native {
    color: #fff;
    background-color: @primary; // 主题色
  }
}
.font-2x {
  font-size: 28px;
}
.coupon-wrapper {
  border-radius: 5px;
  box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
  background: #f8f8f8;
  .coupon-amount {
    border-radius: 14px 14px 0px 0px;
    position: relative;
    text-align: center;
    &.amount-top-bg {
      background: linear-gradient(to right, #ff2a2a, #ff4b82);
    }
    &.amount-top-bg-disabled {
      background: linear-gradient(to right, #c9c9c9, #777777);
    }
    border-bottom: 1px dashed @border;
    color: #fff;
    .amount-top-img {
      padding: 10px 8px;
      border-radius: 14px 14px 0px 0px;
      background: url("../../../../assets/images/new/coupon-bg-card.png")
        no-repeat -1px;
      background-size: cover;
    }
    .amount-top-img-disabled {
      padding: 10px 8px;
      border-radius: 14px 14px 0px 0px;
      background: url("../../../../assets/images/new/coupon-bg-card-disabled.png")
        no-repeat -1px;
      background-size: cover;
    }
    &.disabled {
      background: #eee;
      color: @disabled;
    }
    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      left: -10px;
      bottom: -10px;
      background: #fff;
      &.left {
        left: unset;
        right: -10px;
      }
    }
  }
  .icon-tip {
    position: absolute;
    right: 16px;
    bottom: 0;
    font-size: 60px;
  }
}
.gray-6 {
  color: #666;
}
.lh-2 {
  line-height: 2;
}
.white {
  background-color: #fff;
}
.content-modal {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.main-box {
  max-height: 80vh;
  overflow-y: auto;
}
.list {
  padding: 19px 26px;
  border-top: 1px solid #f2f2f2;
  line-height: calc(5 / 2);
}
.emty {
  font-size: 18px;
  text-align: center;
  img {
    width: 88px;
    height: 51px;
    margin-bottom: 22px;
  }
}
/deep/.ant-modal-body {
  padding: 0;
}
</style>
