<template>
  <div class="mt-4x">
    <div class="title-block">会员数据</div>
    <div class="list">
      <div class="item">
        <div class="left">
          <p class="price">消费金额</p>
          <p class="mony">{{ memberInfoOne.orderTotalPrice | money }}</p>
          <p>总单数：{{ memberInfoOne.orderTradeCount }}</p>
        </div>
        <QmpButton class="btn" size="small" @click="monetary">明细</QmpButton>
      </div>
    </div>
    <!-- 消费明细弹窗 -->
    <a-modal
      centered
      title="消费明细"
      :visible="monetaryShow"
      :confirm-loading="confirmLoading"
      @cancel="close"
      width="80%"
    >
      <div class="content-modal">
        <div class="search">
          <a-input
            size="large"
            placeholder="请输入订单号"
            v-model="searchKey"
            @pressEnter="onSearch"
            allow-clear
          />
          <div class="search-icon pointer gray" @click="onSearch">
            <span class="line"></span>
            <i class="icon iconfont icon-sousuo"></i>
            <span>搜索</span>
          </div>
        </div>
        <div class="content" v-for="item in monetaryList" :key="item.id">
          <a-row>
            <a-col :span="12">
              <p>下单时间：{{ item.createTime | date("YYYY-MM-DD hh:mm") }}</p>
              <p>总单业态集：{{ item.ownerFormatTypeInfos || "-" }}</p>
              <p>业务类型：{{ item.orderBusinessTypeText }}</p>
              <p>应收金额：{{ item.totalAmount | money }}</p>
              <p>会员积分抵扣：{{ item.integralDeductionAmount }}</p>
              <p>支付方式：{{ item.payWayName }}</p>
            </a-col>
            <a-col :span="12">
              <p>总单号：{{ item.id }}</p>
              <p>销售员：{{ item.salespersonName }}</p>
              <p>下单渠道：{{ item.buyChannelsText }}</p>
              <p>优惠金额：{{ item.discountAmount | money }}</p>
              <p>实收金额：{{ item.actualAmount | money }}</p>
            </a-col>
          </a-row>
          <div class="footer">
            <QmpButton
              size="small"
              :disabled="!chargeBackList.includes(item.orderStatus)"
              @click="chargeBack(item.id)"
              >退单</QmpButton
            >
            <QmpButton size="small" @click="goProductDetails(item)"
              >详情</QmpButton
            >
          </div>
        </div>
        <div v-show="!monetaryList.length" class="emty">
          <img src="../../../../assets/images/new/kong.png" alt="" />
          <p class="gray">无此订单，请重新输入查询</p>
        </div>
      </div>
      <div slot="footer">
        <a-pagination
          v-model="tablePagination.current"
          :total="tablePagination.total"
          @change="changeMonetary"
          :defaultPageSize="tablePagination.pageSize"
          show-less-items
        />
      </div>
    </a-modal>
    <a-modal
      centered
      title="退整单"
      :visible="refundShow"
      :confirm-loading="confirmLoading"
      @cancel="refundClose"
      width="80%"
    >
      <div class="p-4x">
        <a-row class="mb-1x">
          <a-col :span="5">订单编号：</a-col>
          <a-col :span="10">{{ refundInfo.orderId }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">订单状态：</a-col>
          <a-col :span="10">{{ refundInfo.orderStatusText }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">本次退款产品：</a-col>
          <a-col :span="10">{{ refundInfo.productName }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">本次应退金额：</a-col>
          <a-col :span="10">{{ refundInfo.totalLeftAmount | money }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">本次退款手续费：</a-col>
          <a-col :span="10">{{
            refundInfo.totalCommissionAmount | money
          }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">本次退款金额：</a-col>
          <a-col :span="10">{{
            refundInfo.totalRefundableAmount | money
          }}</a-col>
        </a-row>

        <a-row>
          <a-col :span="5">退款备注：</a-col>
          <a-col :span="19"
            ><a-input
              type="textarea"
              v-model="remarks"
              placeholder="请输入退款备注"
            ></a-input
          ></a-col>
        </a-row>
      </div>
      <div slot="footer">
        <a-button type="primary" @click="confirmRefund">确认退款</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { refundOrder, refundOrderCheck } from "../../../../api/order";
import { orderConsumedetail } from "../../../../api/search";
export default {
  name: "MemberData",
  props: {
    memberInfoOne: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      monetaryShow: false,
      monetaryList: [],
      confirmLoading: false,
      searchKey: "",
      tablePagination: {
        pageSize: 4,
        current: 1,
        total: 0
      },
      chargeBackList: [3, 9, 16, 18], // 退单按钮可选中状态值
      orderId: "",
      refundInfo: {},
      refundShow: false,
      remarks: ""
    };
  },
  methods: {
    // 退单成功
    confirmRefund() {
      this.refundShow = false;
      refundOrder({
        mainOrderId: this.orderId,
        remarks: this.remarks
      })
        .then(res => {
          // 消费明细
          this.orderConsumedetailLoad();
          this.$message.success(
            res.refundFailMsg ? res.refundFailMsg : "退单成功！"
          );
        })
        .catch(() => {});
    },
    // 取消退款弹窗
    refundClose() {
      this.refundShow = false;
    },
    chargeBack(id) {
      this.orderId = id;
      refundOrderCheck({
        mainOrderId: id
      }).then(res => {
        this.refundShow = true;
        this.refundInfo = res || {};
      });
    },
    goProductDetails(item) {
      console.log("去产品详情页面", item);
      this.$router.push({
        path: "/search/OrderDetail",
        query: {
          searchKey: item.id
        }
      });
    },
    onSearch() {
      this.tablePagination.current = 1;
      this.orderConsumedetailLoad();
    },
    changeMonetary(page) {
      this.tablePagination.current = page;
      this.orderConsumedetailLoad();
    },
    // 消费金额明细
    monetary() {
      this.orderConsumedetailLoad();
    },
    // 会员消费明细
    orderConsumedetailLoad() {
      orderConsumedetail({
        memberId: this.memberInfoOne.id,
        orderId: this.searchKey,
        page: this.tablePagination.current,
        size: this.tablePagination.pageSize
      }).then(res => {
        this.monetaryShow = true;
        this.monetaryList = res.records;
        this.tablePagination.total = res.total;
      });
    },
    close() {
      this.searchKey = "";
      this.tablePagination = {
        pageSize: 4,
        current: 1,
        total: 0
      };
      this.monetaryShow = false;
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.list {
  display: flex;
  justify-content: space-between;
  margin: 28px 0;
  .item {
    width: 440px;
    height: 178px;
    border-radius: 14px;
    background: #f6f6f6;
    display: flex;
    justify-content: flex-start;
    padding: 18px 23px;
    line-height: 2;
    .left {
      width: 100%;
      .price {
        font-size: 22px;
      }
      .mony {
        font-size: 34px;
      }
      .btn {
        margin-left: 146px;
      }
    }
    button {
      width: 120px;
    }
  }
}
.content-modal {
  width: 100%;
  .search {
    position: relative;
    width: 420px;
    margin: 24px 0 24px 27px;
    .search-icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      font-size: 18px;
      .icon-sousuo {
        margin: 0 5px 0 20px;
        font-size: 24px;
      }
      .line {
        display: inline-block;
        width: 1px;
        height: 18px;
        background-color: @gray;
        margin-left: 20px;
      }
    }
  }
  .content {
    padding: 0 26px;
    border-top: 1px solid #e8e8e8;
    line-height: 3;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    height: max-content;
    padding: 10px 0;
    button {
      &:nth-child(2) {
        margin: 0 20px;
      }
    }
  }
}
.emty {
  font-size: 18px;
  text-align: center;
  img {
    width: 88px;
    height: 51px;
    margin-bottom: 22px;
  }
}
/deep/.ant-modal-body {
  padding: 0;
}
</style>
