<template>
  <QmpLayout title="会员详情" bgColor="#ffffff">
    <div class="root">
      <MemberHeader :memberInfoOne="memberInfoOne" />
      <MemberAsset :vipRes="memberInfoOne" />
      <!-- 头部标题 和 更多 -->
      <div class="flex-between">
        <h1>产品列表</h1>
        <div class="blue font-xxl pointer" @click="productMore">
          更多 <a-icon type="right" />
        </div>
      </div>
      <!-- 产品列表 -->
      <div class="list" v-for="item in productData" :key="item.id">
        <img
          :src="item.skuImages || require('../../../assets/images/new/pt.png')"
          alt=""
        />
        <div class="detila">
          <h1 class="font-xxxl flex-between">
            {{ item.productName }} <span>x{{ item.buyNum }}</span>
          </h1>
          <p>
            使用有效期：{{ item.beginValidTime | date }} ~
            {{ item.endValidTime | date }}
          </p>
          <p>预约日期：{{ item.useDate | date }}</p>
          <p>
            预约时段：{{ item.appointmentPeriodStartTime | date
            }}{{ item.appointmentPeriodEndTime | date }}
          </p>
          <p>购买数量：{{ item.buyNum }}</p>
        </div>
      </div>
      <MemberCoupon :memberId="memberId" />
      <MemberData :memberInfoOne="memberInfoOne" />
    </div>
  </QmpLayout>
</template>

<script>
import { orderOneMemberInfo } from "@/api/member";
import { orderMemberProduct } from "@/api/search";
import QmpLayout from "@/components/common/QmpLayout.vue";
import MemberAsset from "./components/MemberAsset.vue";
import MemberHeader from "./components/MemberHeader.vue";
import MemberCoupon from "./components/MemberCoupon.vue";
import MemberData from "./components/MemberData.vue";
export default {
  name: "MemberIndex",
  components: {
    QmpLayout,
    MemberHeader,
    MemberAsset,
    MemberCoupon,
    MemberData
  },
  data() {
    return {
      memberInfoOne: {},
      pageSize: 2, // 默认展示两条
      current: 1,
      productData: [],
      memberId: ""
    };
  },
  created() {
    this.memberId = this.$route.query.searchKey;
    this.getMemberInfoOne();
  },
  methods: {
    productMore() {
      this.$router.push({
        name: "ProductList",
        query: {
          keyword:
            this.memberInfoOne.mobile ||
            this.memberInfoOne.idCardNumber ||
            this.memberInfoOne.wristBand ||
            this.memberId
        }
      });
    },
    getOrderMemberProduct() {
      orderMemberProduct({
        keyword:
          this.memberInfoOne.mobile ||
          this.memberInfoOne.idCardNumber ||
          this.memberInfoOne.wristBand ||
          this.memberId,
        size: this.pageSize,
        page: this.current
      }).then(res => {
        this.productData = res.records;
      });
    },
    // 2022/03
    /**
     * 会员资产区域
     */
    // 根据会员id获取会员详情
    getMemberInfoOne() {
      orderOneMemberInfo({ memberId: this.memberId }).then(res => {
        this.memberInfoOne = res || {};
        this.getOrderMemberProduct();
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.root {
  padding: 18px 30px 0;
}
.list {
  display: flex;
  align-items: center;
  padding: 25px 0 21px;
  img {
    width: 200px;
    height: 200px;
    margin-right: 33px;
    border-radius: 8px;
  }
  p {
    font-size: 22px;
    color: @gray;
  }
  .detila {
    flex: 1;
    line-height: 2;
  }
}
</style>
